<template>
  <div style="width: 100%">
    <Header
      :details="{
        title: 'Unauthorized',
        actions: false,
      }"
    />

    <div class="sub-header"></div>

    <div class="client" id="main-container">
      <p>Sorry, you are not authorised to view this page with your current user role ( {{ user.role.name }} ).</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "unauthorized",
  computed: {
    user() {
      return this.$store.state.global.user;
    },
  },
});
</script>

<style scoped></style>
